import Web3 from "web3";
import big from "big.js";
// import { utils } from "ethers";
// tb合约pg合约方法
export default {
  async getauthorization(tokenAddress) {
    try {
      var code = 1
      const authorization = localStorage.getItem(tokenAddress);
      if (process.env.VUE_APP_IS_SIGN == 0) {
        return {code: 0}
      }
      if (authorization) {
        const authorizationData = JSON.parse(authorization);
        const currentTime = new Date().getTime(); // 获取当前时间戳
        const expirationTime = parseInt(authorizationData.expirationTime, 10); // 解析存储的过期时间

        if (currentTime > expirationTime || authorizationData.id !== tokenAddress) {
          // 过期时间已过或 ID 不匹配，需要重新授权...
          console.log('过期时间已过或 ID 不匹配，需要重新授权...')
          var signtype1 = await this.signature(tokenAddress)
          // console.log(signtype1,'signtypesigntypesigntypesigntype')
          if (signtype1.code == 0) {
            code = 0
          } else {
            code = 1
          }
        } else {
          // this.authorizedId = authorizationData.id; // 设置当前授权的 ID
          // 授权仍有效...
          console.log('授权仍有效...')
          code = 0
        }
      } else {
        // 尚未进行授权...
        var signtype = await this.signature(tokenAddress)
        // console.log(signtype,'signtypesigntypesigntypesigntype')
        if (signtype.code == 0) {
          code = 0
        } else {
          code = 1
        }
      }

      return {code: code};
    } catch (error) {
      //  console.log(error);
    }
  },
//
  async signature(tokenAddress) {
    try {
      var code = 1
      console.log("签名")
      const ethereum = window.ethereum;
      var characterstring = this.generateRandomString(10)
      let web3 = new Web3(Web3.givenProvider || ethereum);
      // 检查是否存在以太坊提供者（如 MetaMask）
      if (typeof web3 !== 'undefined') {
        // 使用 MetaMask 提供的提供者
        web3 = new Web3(web3.currentProvider);

      } else {
        // 提供者未找到，提示用户安装 MetaMask 或其他以太坊钱包
        alert('请安装 MetaMask 钱包');
      }
      // 发送签名请求
      // 要签名的数据
      const data = characterstring;
      const signature = await web3.eth.personal.sign(data, tokenAddress, '');
      // console.log('Signature:', signature);
      if (signature) {
        this.authorize(tokenAddress)
        code = 0
      } else {
        code = 1
      }
      return {code: code};
    } catch (error) {
      //  console.log(error);
    }
  },
  authorize(tokenAddress) {
    const currentTime = new Date().getTime(); // 获取当前时间戳
    const expirationTime = currentTime + 30 * 24 * 60 * 60 * 1000; // 设置过期时间为当前时间加上一天

    const authorizationData = {
      id: tokenAddress, // 替换为您的 ID
      expirationTime: expirationTime
    };

    localStorage.setItem(tokenAddress, JSON.stringify(authorizationData)); // 存储授权数据及过期时间

    // this.authorizedId = authorizationData.id; // 设置当前授权的 ID

    // 执行授权操作...
  },
  // 随机字符串
  generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  },


};
