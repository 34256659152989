// src/i18n.js
import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/lang/en";
import zhTW from "@/lang/zhTW";
import ja from "@/lang/ja";
import ko from "@/lang/ko";
import pt from "@/lang/pt";
import th from "@/lang/th";
import ru from "@/lang/ru";
import de from "@/lang/de";
import vi from "@/lang/vi";
import mn from "@/lang/mn";

Vue.use(VueI18n);

const messages = {
  en: en,
  zhTW: zhTW,
  ja: ja,
  ko: ko,
  pt: pt,
  th: th,
  ru: ru,
  de: de,
  vi: vi,
  mn: mn,
};
const i18n = new VueI18n({
  locale: "en",
  messages,
});
export default i18n;
